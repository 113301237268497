export enum GameState {
    loading = "loading",
    waiting = "waiting",
    getready = "getready",
    question = "question",
    nextquestion = "nextquestion",
    gameover = "gameover",
    leaderboard = "leaderboard"
}

export interface Game {
    id?: string
    questions: number
    startsAt: Date
    teamsCount: number
    state: {
        name: GameState
        round: number
    }
    timer?: number
}
