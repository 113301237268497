import React, {useEffect, useState} from 'react'
import * as firebase from "firebase"

interface SessionContextProps {
    isLoggedIn: boolean
}

export const SessionContext = React.createContext<SessionContextProps>({ isLoggedIn: false })

export const SessionProvider: React.FC = (props) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            const userIsLoggedIn = user !== null
            setIsLoggedIn(userIsLoggedIn)
        })
    })

    const value = { isLoggedIn }

    return (
        <SessionContext.Provider value={value}>
            {props.children}
        </SessionContext.Provider>
    )
}
