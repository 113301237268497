import React, {useState} from 'react'
import {PageHeader, Input, Button, Alert} from "antd"
import styled from "styled-components"
import firebase from "firebase"
import {GameService} from "../services/game-service"
import { useHistory } from 'react-router-dom'
import {Container, Middle} from "./common"
import { Config } from '../config'
import {StarOutlined} from "@ant-design/icons"
interface NewGameProps {}

enum ScreenState {
    newGame,
    loading,
    error
}

export const NewGame: React.FC<NewGameProps> = () => {
    const [gameId, setGameId] = useState('')
    const [state, setState] = useState<ScreenState>(ScreenState.newGame)
    const [error, setError] = useState<string|null>(null)
    const history = useHistory()

    const enterGameTapped = async () => {
        setState(ScreenState.loading)

        const gameExists = await fetch(`${Config.apiEndpoint}/enterGame?id=${gameId}`)
            .then(resp => resp.json())
            .then((json: { exists: boolean }) => json.exists)

        if (!gameExists) {
            setError(`This game doesn't exist. Please double check the Game ID.`)
            setState(ScreenState.error)
            return
        }

        const game = await firebase.firestore()
            .collection("games")
            .doc(gameId)
            .get()

        const data = game.data()
        if (data) {
            if (data.questions > 0) {
                GameService.setGameId(gameId)
                history.push("/teams")
            } else {
                setError('This game has no questions.')
                setState(ScreenState.error)
            }
        } else {
            setError('This game does not exist.')
            setState(ScreenState.error)
        }
    }

    return (
        <Container>
            <PageHeader
                className="site-page-header"
                title="Quiz Night"
                subTitle="Lock down your answer!"
                style={{ paddingLeft: 0 }}
            />
            <Middle>
                {state === ScreenState.error &&
                    <Alert message={error} type="error" style={{ marginBottom: 15, width: '100%' }}/>
                }
                <Input
                    size="large"
                    placeholder="Game ID"
                    value={gameId}
                    onChange={(e) => {
                        setState(ScreenState.newGame)
                        setGameId(e.target.value.toLowerCase())
                    }}
                    allowClear={true}
                />
                <Hint>Enter the host's Game ID to continue</Hint>
                <Button type="primary" shape="round" size={'large'} style={{ marginTop: 40 }} onClick={enterGameTapped} loading={state === ScreenState.loading}>
                    Enter Game
                </Button>
            </Middle>
            <HostButton
                type={"default"}
                size={"large"}
                icon={<StarOutlined />}
                onClick={() => history.push('/admin')}
            >
                I'm the Quiz Master!
            </HostButton>
        </Container>
    )
}

const Hint = styled.div`
    font-size: 10pt;
    color: gray;
`

const HostButton = styled(Button)`
    position: fixed;
    bottom: 16px;    
`
