import { Team } from "../models/team"

enum GameServiceKeys {
    gameId = "qn.gameid",
    team = "qn.team"
}

class PrivateGameService {
    gameId(): string {
        return localStorage.getItem(GameServiceKeys.gameId)!
    }

    setGameId(gameId: string|null) {
        if (gameId) { localStorage.setItem(GameServiceKeys.gameId, gameId) }
        else { localStorage.removeItem(GameServiceKeys.gameId )}
    }

    team(): Team {
        const savedTeam = localStorage.getItem(GameServiceKeys.team)!
        return JSON.parse(savedTeam) as Team
    }

    setTeam(team: Team|null) {
        if (team) {
            localStorage.setItem(GameServiceKeys.team, JSON.stringify(team))
        } else {
            localStorage.removeItem(GameServiceKeys.team)
        }
    }
}

export const GameService = new PrivateGameService()
