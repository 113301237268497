import React from 'react'
import * as firebase from "firebase/app"
import "firebase/firestore"
import {SessionProvider} from "../contexts/session-context"

const firebaseConfig = {
  apiKey: "AIzaSyAIWztZB9qDudtKzioX-KGzf9wh1Y9RKKM",
  authDomain: "quiz-night-8766d.firebaseapp.com",
  databaseURL: "https://quiz-night-8766d.firebaseio.com",
  projectId: "quiz-night-8766d",
  storageBucket: "quiz-night-8766d.appspot.com",
  messagingSenderId: "1065416692508",
  appId: "1:1065416692508:web:4d5d699aa784aee55220d9",
  measurementId: "G-WEEE195T6K"
}

firebase.initializeApp(firebaseConfig)


const App: React.FC = (props) => {
  return (
      <SessionProvider>
        <div className="App">
          {props.children}
        </div>
      </SessionProvider>
  )
}

export default App
