import React, {useEffect, useState} from 'react'
import {Button, Input, Modal, PageHeader, Tag, Typography} from "antd"
import {useHistory, useParams} from 'react-router-dom'
import {Container, EmojiButton, EmojiHint, Middle} from "./common"
import {Game, GameState} from '../models/game'
import {Round} from "../models/round"
import firebase from 'firebase'
import {BigLoader, SmallLoader} from "./loader"
import styled from "styled-components"
import {Team} from '../models/team'
import {CheckCircleOutlined, CheckOutlined, DeleteOutlined} from "@ant-design/icons"
import Picker, {IEmojiData} from 'emoji-picker-react'
import {useTimer} from 'react-timer-hook'
import {Answer} from "../models/answer"
import {Leaderboard} from "./leaderboard"
import {Config} from "../config"

const {Title} = Typography

interface AdminManageGameProps {
}

export const AdminManageGame: React.FC<AdminManageGameProps> = (props) => {
    const [game, setGame] = useState<Game|null>(null)
    const [rounds, setRounds] = useState<Round[]|null>(null)
    const [teams, setTeams] = useState<Team[]|null>(null)
    const [showQuestions, setShowQuestions] = useState(true)
    const [showQuestionModal, setShowQuestionModal] = useState(false)
    const [newQuestion, setNewQuestion] = useState<string|null>(null)
    const [isAddingNewQuestion, setIsAddingNewQuestion] = useState(false)
    const [isDeleting, setIsDeleting] = useState<number|null>(null)
    const [emoji, setEmoji] = useState<string>('❓')
    const [showEmojis, setShowEmojis] = useState(false)
    const [gameStateLoading, setGameStateLoading] = useState(false)
    const [answers, setAnswers] = useState<Answer[]>([])

    const history = useHistory()
    const {gameId} = useParams()
    const { seconds, restart } = useTimer({ expiryTimestamp: Date.now() - 1, onExpire: () => timerFired() })

    useEffect(() => {
        if (teams) { return }
        getTeams()
        getRounds()
    }, [teams, gameId])

    /// Real-time Game Snapshot
    useEffect(() => {
        if (game) { return }
        firebase.firestore()
            .collection('games')
            .doc(gameId)
            .onSnapshot((doc) => {
                const data = doc.data()
                if (!data) {
                    return
                }

                const refreshTeams = (teams ?? []).length !== data.teamsCount
                console.log((teams ?? []).length, data.teamsCount, refreshTeams)

                setGame({
                    questions: data.questions,
                    startsAt: data.startsAt.toDate(),
                    teamsCount: data.teamsCount,
                    state: data.state,
                })

                if (refreshTeams) {
                    getTeams()
                }
            }, (error) => {
                console.error('real-time game snapshot', error)
            })
    }, [game])

    /// Real-time Answers Snapshot
    useEffect(() => {
        if (!game) { return }
        if ([GameState.waiting, GameState.gameover, GameState.leaderboard].includes(game.state.name)) { return }

        firebase.firestore()
            .collection('games').doc(gameId)
            .collection('rounds').doc(`${game.state.round}`)
            .collection('answers').orderBy('answeredAt', 'asc')
            .onSnapshot((collection) => {
                const allAnswers: Answer[] = collection.docs.map(answer => {
                    const data = answer.data()
                    return {
                        team: answer.id,
                        emoji: data.emoji,
                        answer: data.answer,
                        answeredAt: data.answeredAt.toDate(),
                        isCorrect: data.isCorrect,
                    }
                })
                setAnswers(allAnswers)
            }, (error) => {
                console.error('real-time answers snapshot', error)
            })
    }, [game])

    const getRounds = async () => {
        const roundsSnap = await firebase.firestore()
            .collection("games").doc(gameId)
            .collection('rounds').get()

        const allRounds = roundsSnap.docs.map(doc => {
            const data = doc.data()
            return {
                round: parseInt(doc.id),
                question: data.question,
                emoji: data.emoji,
            } as Round
        })
        setRounds(allRounds)
        return allRounds
    }

    const getTeams = async () => {
        try {
            const teamsSnap = await firebase.firestore()
                .collection("games").doc(gameId)
                .collection("teams").get()

            const teams = teamsSnap.docs.map(doc => doc.data() as Team)
            setTeams(teams)
        } catch (e) {
            console.error('getTeams', e)
        }
    }

    const addQuestion = () => {
        if (!rounds) { return }
        if (!newQuestion) { return }
        if (newQuestion.length === 0) { return }

        setIsAddingNewQuestion(true)
        const newRound = rounds.length + 1

        firebase.firestore()
            .collection('games').doc(gameId)
            .collection('rounds').doc(`${newRound}`)
            .set({ question: newQuestion, emoji })
            .then(() => {
                return getRounds()
            })
            .then(() => {
                setIsAddingNewQuestion(false)
                setShowQuestionModal(false)
                setNewQuestion(null)
                setEmoji('❓')
            })
            .catch(console.error)
    }

    const removeQuestion = (round: number) => {
        setIsDeleting(round)
        firebase.firestore()
            .collection("games").doc(gameId)
            .collection('rounds').doc(`${round}`)
            .delete()
            .then(() => {
                return getRounds()
            })
            .then(() => {
                setIsDeleting(null)
            })
            .catch(err => {
                console.error(err)
                setIsDeleting(null)
            })
    }

    const emojiChosen = (_: any, emoji: IEmojiData) => {
        setEmoji(emoji.emoji)
        setShowEmojis(false)
    }

    const startTimer = () => {
        const time = Date.now() + Config.getReadyTimerLength
        restart(time)
    }

    const prepareGame = () => {
        if (!rounds) { return }
        setGameStateLoading(true)
        const firstRound = rounds[0]

        firebase.firestore()
            .collection("games").doc(gameId)
            .set({
                state: {
                    name: "getready",
                    round: firstRound.round
                }
            }, { merge: true })
            .then(() => {
                startTimer()
            })
            .catch((error) => {
                console.error(error)
                setGameStateLoading(false)
            })
    }

    const startGame = () => {
        setGameStateLoading(true)

        firebase.firestore()
            .collection("games").doc(gameId)
            .set({
                state: {
                    name: "question",
                }
            }, { merge: true })
            .then(() => {
                setGameStateLoading(false)
            })
            .catch((error) => {
                console.error(error)
                setGameStateLoading(false)
            })
    }

    const findNextRound = (): number|null => {
        if (!game) { return null }
        if (!rounds) { return null }
        const currentRoundIndex = rounds.findIndex(round => round.round === game.state.round)
        if (currentRoundIndex === -1) { return null }

        const nextRoundIndex = currentRoundIndex + 1
        if (typeof rounds[nextRoundIndex] === 'undefined') {
            return null
        } else {
            const nextRound = rounds[nextRoundIndex]
            return nextRound.round
        }
    }

    const prepareNextQuestion = () => {
        if (!rounds) { return }
        if (!game) { return }
        setGameStateLoading(true)

        const nextRound = findNextRound()

        if (!nextRound) {
            console.log('no next question')
        } else {
            firebase.firestore()
                .collection("games").doc(gameId)
                .set({
                    state: {
                        name: "nextquestion",
                        round: nextRound
                    }
                }, { merge: true })
                .then(() => {
                    startTimer()
                })
                .catch((error) => {
                    console.error(error)
                    setGameStateLoading(false)
                })
        }
    }

    const nextQuestion = () => {
        if (!rounds) { return }
        if (!game) { return }
        setGameStateLoading(true)

        firebase.firestore()
            .collection("games").doc(gameId)
            .set({
                state: {
                    name: "question",
                }
            }, { merge: true })
            .then(() => {
                setGameStateLoading(false)
            })
            .catch((error) => {
                console.error(error)
                setGameStateLoading(false)
            })
    }

    const timerFired = () => {
        if (!game) { return }
        switch (game.state!.name) {
            case GameState.getready: {
                startGame()
                break
            }
            case GameState.nextquestion: {
                nextQuestion()
                break
            }
        }
    }

    const markAnswerCorrect = (answer: Answer) => {
        if (!game) { return }

        firebase.firestore()
            .collection('games').doc(gameId)
            .collection('rounds').doc(`${game.state.round}`)
            .collection('answers').doc(answer.team)
            .set({
                isCorrect: !answer.isCorrect
            }, { merge: true })
            .catch(console.error)
    }

    const endGame = () => {
        if (!game) { return }
        setGameStateLoading(true)

        firebase.firestore()
            .collection("games").doc(gameId)
            .set({
                state: {
                    name: "gameover"
                }
            }, { merge: true })
            .then(() => {
                setGameStateLoading(false)
            })
            .catch((error) => {
                console.error(error)
                setGameStateLoading(false)
            })
    }

    const showLeaderboard = () => {
        if (!game) { return }
        setGameStateLoading(true)

        firebase.firestore()
            .collection("games").doc(gameId)
            .set({
                state: {
                    name: "leaderboard"
                }
            }, { merge: true })
            .then(() => {
                setGameStateLoading(false)
            })
            .catch((error) => {
                console.error(error)
                setGameStateLoading(false)
            })
    }

    let canEdit = false
    let canShowTeams = true
    let canShowAnswers = false
    let canPlay = false
    let canShowLeaderboard = false
    let startDisabled = true
    let hasStarted = false

    if (game) {
        if  (game.state.name === GameState.waiting) {
            canEdit = true
            canShowTeams = true
        }

        if (![GameState.leaderboard].includes(game.state.name)) {
            canPlay = true
        }

        canShowAnswers = ![GameState.waiting, GameState.gameover, GameState.leaderboard].includes(game.state.name)
        canShowLeaderboard = [GameState.gameover, GameState.leaderboard].includes(game.state.name)
        hasStarted = ![GameState.waiting, GameState.gameover, GameState.leaderboard].includes(game.state.name)

        if (canShowLeaderboard) {
            canShowTeams = true
        }
    }

    if (rounds) {
        startDisabled = rounds.length === 0
    }

    let teamsYetToAnswer = teams ? teams.slice() : []
    if (teamsYetToAnswer.length > 0) {
        for (let i = 0; i < answers.length; i++) {
            teamsYetToAnswer = teamsYetToAnswer.filter(team => team.name !== answers[i].team)
        }
    }
    console.log('teamsYetToAnswer', teamsYetToAnswer)

    let activeRounds: number[] = []
    if (rounds && game?.state.round && game.state.name !== GameState.waiting) {
        activeRounds = [game.state.round - 1, game.state.round, game.state.round + 1]
        if (activeRounds[2] > rounds.length) { activeRounds.pop() }
        if (activeRounds[0] < 1) { activeRounds.shift() }
    }
    console.log(game?.state.round, activeRounds)

    return (
        <>
            <PageHeader
                title={"Manage Game"}
                onBack={() => history.goBack()}
            />

            <Container>
                {!game &&
                    <Middle>
                        <BigLoader />
                    </Middle>}
                {game &&
                    <>
                        <div>
                            <CurrentState>
                                <span>
                                    <strong>Current State</strong>: {game.state.name.toUpperCase()}&nbsp;
                                    {game.state.name === 'question' && <span>{game.state.round}</span>}
                                </span>

                                {canPlay &&
                                    <div style={{marginTop: 16}}>
                                        {game.state.name === GameState.waiting &&
                                            <Button size={"large"} type={"primary"} loading={gameStateLoading} onClick={prepareGame} disabled={startDisabled}>
                                                Start Game →
                                            </Button>}
                                        {game.state.name === GameState.getready &&
                                            <Button size={"large"} type={"primary"} disabled={true} loading={true}>
                                                Starting game in {seconds}…
                                            </Button>}
                                        {game.state.name === GameState.nextquestion &&
                                            <Button size={"large"} type={"primary"} disabled={true} loading={true}>
                                                Showing Next Question in {seconds}…
                                            </Button>}
                                        {game.state.name === GameState.question && findNextRound() &&
                                            <Button size={"large"} type={"primary"} loading={gameStateLoading} onClick={prepareNextQuestion}>
                                                Proceed to Next Question →
                                            </Button>}
                                        {game.state.name === GameState.question && !findNextRound() &&
                                            <Button size={"large"} type={"primary"} loading={gameStateLoading} onClick={endGame}>
                                                End Game &amp; Show Answers →
                                            </Button>}
                                        {game.state.name === GameState.gameover &&
                                            <Button size={"large"} type={"primary"} loading={gameStateLoading} onClick={showLeaderboard}>
                                                Save &amp; Show Leaderboard →
                                            </Button>}
                                    </div>}
                            </CurrentState>
                        </div>

                        {showQuestions && !canShowLeaderboard &&
                            <>
                                <Title level={4}>
                                    Questions
                                    <Hide onClick={() => setShowQuestions(false)}>hide</Hide>
                                </Title>
                                <ol style={{ listStyle: "none", marginLeft: -20 }}>
                                    {rounds && rounds
                                        .sort((a, b) => a.round - b.round)
                                        .filter(round => {
                                            if (activeRounds.length === 0) { return true }
                                            return activeRounds.includes(round.round)
                                        })
                                        .map(round => (
                                            <Question key={round.round} seq={`${round.round}`}>
                                                {hasStarted && round.round === game!.state.round
                                                    ? <strong>{round.emoji}&nbsp;{round.question}</strong>
                                                    : <span>{round.emoji}&nbsp;{round.question}</span>}

                                                {isDeleting && isDeleting === round.round &&
                                                    <SmallLoader />}

                                                {canEdit && (!isDeleting || isDeleting !== round.round) &&
                                                    <Button
                                                        type={"link"}
                                                        icon={<DeleteOutlined style={{ fontSize: 13, marginRight: 8 }} />}
                                                        onClick={() => removeQuestion(round.round)}
                                                    />}
                                            </Question>
                                        ))}

                                    {canEdit &&
                                        <Question style={{ listStyleType: 'none', marginLeft: -20 }}  seq={''}>
                                            <Button type={"link"} onClick={() => setShowQuestionModal(true)}>
                                                + Add Question
                                            </Button>
                                        </Question>}
                                </ol>
                            </>}

                        {!showQuestions &&
                            <Show onClick={() => setShowQuestions(true)}>
                                Show Questions
                            </Show>}

                        {canShowLeaderboard &&
                        <>
                            <Title level={4}>Leaderboard</Title>
                            <Leaderboard gameId={gameId ?? ''} />
                            <LeaderboardHint>Please wait up to 30 seconds for leaderboard to be calculated. It will update in real time.</LeaderboardHint>
                        </>}

                        {canShowAnswers &&
                        <div>
                            <Title level={4}>Question {game.state.round} Answers&nbsp;</Title>
                            {teamsYetToAnswer.length > 0 &&
                                <strong>
                                    <Tag color={"processing"}>{answers.length} teams answered</Tag>
                                    <Tag color={"warning"}>{teamsYetToAnswer.length} {teamsYetToAnswer.length === 1 ? 'team' : 'teams'} yet to answer</Tag>
                                </strong>}

                            {teamsYetToAnswer.length === 0 &&
                            <Tag color={"success"} icon={<CheckCircleOutlined />}><strong>All teams have answered</strong></Tag>}
                            <ol>
                                {answers.map(answer => (
                                    <AnswerDescription
                                        key={answer.team}
                                        answer={answer}
                                        markedCorrect={() => markAnswerCorrect(answer)}
                                    />
                                ))}
                                {teamsYetToAnswer.map(team => (
                                    <NotAnsweredYet
                                        key={team.name}
                                        team={team}
                                    />
                                ))}
                            </ol>
                        </div>}

                        {canShowTeams &&
                            <div>
                                <Title level={4}>Teams</Title>
                                <ol>
                                    {teams && teams
                                        .map(team => <TeamDescription team={team} key={team.name} />)}
                                    {(!teams || !teams.length) &&
                                        <span>No teams have entered yet</span>
                                    }
                                </ol>
                            </div>}

                    </>}

                    <Modal
                        title={"New Question"}
                        visible={showQuestionModal}
                        onCancel={() => setShowQuestionModal(false)}
                        onOk={addQuestion}
                        okButtonProps={{ loading: isAddingNewQuestion }}
                    >
                        <QuestionSection>
                            <EmojiButton onClick={() => setShowEmojis(showing => !showing)}>
                                {emoji}
                            </EmojiButton>
                            <Input size={"large"} placeholder={"What is your question?"} value={newQuestion ?? ''} onChange={(e) => setNewQuestion(e.target.value)} />
                        </QuestionSection>
                        <EmojiHint><span role={"img"} aria-label={"up arrow emoji"}>⬆️</span> Select an emoji to describe your question!</EmojiHint>

                        {showEmojis &&
                            <div style={{ marginTop: 16 }}>
                                <Picker onEmojiClick={emojiChosen}/>
                            </div>}
                    </Modal>
            </Container>
        </>
    )
}

const TeamDescription: React.FC<{ team: Team }> = (props) => {
    return (
        <TeamLi>
            <span>{props.team.emoji}</span>&nbsp;
            <strong>{props.team.name}</strong>&nbsp;
            <span style={{ color: "lightgray" }}>
                {props.team.players.length} player{props.team.players.length === 1 ? '' : 's'}
            </span><br />
            Players: {props.team.players.join(", ")}
        </TeamLi>
    )
}

const AnswerDescription: React.FC<{ answer: Answer, markedCorrect: () => void }> = (props) => {
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!isLoading) { return }
        setIsLoading(false)
    }, [props.answer.isCorrect])

    const markAsCorrect = () => {
        setIsLoading(true)
        props.markedCorrect()
    }

    return (
        <TeamLi>
            <span>{props.answer.emoji}</span>&nbsp;
            <strong>{props.answer.team}</strong>&nbsp;
            <span style={{ color: "lightgray" }}>
                {props.answer.answeredAt.toLocaleTimeString()}
            </span><br />
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <span>Answer: <strong>{props.answer.answer}</strong></span>
                {isLoading &&
                    <SmallLoader />}
                {!isLoading && props.answer.isCorrect &&
                    <Button size={"small"} type={"primary"} icon={<CheckOutlined />} onClick={markAsCorrect}>Correct</Button>}
                {!isLoading && !props.answer.isCorrect &&
                    <Button size={"small"} onClick={markAsCorrect}>Mark Correct</Button>}
            </div>
        </TeamLi>
    )
}

const NotAnsweredYet: React.FC<{team: Team}> = (props) => {
    return (
        <TeamLi>
            <span>{props.team.emoji}</span>&nbsp;
            <strong>{props.team.name}</strong><br />
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <span><em>Not answered yet…</em></span>
            </div>
        </TeamLi>
    )
}


const Question = styled.li<{seq: string}>`
    line-height: 30px;    
    &:before {
        content: "${props => props.seq}. ";
    }
    padding-left: 20px;
    text-indent: -21px;
`

const TeamLi = styled.li`
    margin-top: 15px;
`

const QuestionSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const CurrentState = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 30px;
`

const Hide = styled.span`
    display: inline-block;
    font-weight: 400;
    font-size: 12pt;
    color: lightgray;
    margin-left: 20px;
    cursor: pointer;
`

const Show = styled.span`
    font-weight: 600;
    font-size: 12pt;
    color: lightgray;
    cursor: pointer;
    margin-bottom: 16px;
`

export const LeaderboardHint = styled.span`
    font-size: 10pt;
    color: #b7b7b7;
    margin-top: 16px;
    margin-bottom: 16px;
`
