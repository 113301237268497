import React, {useEffect, useState} from "react"
import firebase from "firebase"
import {Table} from "antd"

interface LeaderboardPos {
    team: string
    emoji: string
    points: number
    position: number
}

interface LeaderboardProps {
    gameId: string
}

export const Leaderboard: React.FC<LeaderboardProps> = (props) => {
    const [leaderboard, setLeaderboard] = useState<LeaderboardPos[]|null>(null)

    useEffect(() => {
        if (leaderboard !== null) { return }

        const getLeaderboard = () => {
            firebase.firestore()
                .collection('games').doc(props.gameId)
                .collection('leaderboard')
                .orderBy('points', 'desc')
                .onSnapshot((leaderboardRef) => {
                    const board: LeaderboardPos[] = leaderboardRef.docs.map(doc => {
                        const data = doc.data()
                        return {
                            team: doc.id,
                            emoji: data.emoji,
                            points: data.points,
                            position: 0
                        } as LeaderboardPos
                    })
                        .sort((a, b) => b.points - a.points)

                    for (let i = 0; i<board.length; i++) {
                        board[i].position = i + 1
                    }

                    for (let i = 0; i<board.length; i++) {
                        let current = board[i];
                        let next = board[i+1];

                        if (next === undefined) {
                            break
                        }

                        if (next.points === current.points) {
                            next.position = current.position;
                        }
                    }

                    setLeaderboard(board)
                })
        }
        getLeaderboard()

    }, [leaderboard])

    const columns = [
        { title: "#", dataIndex: "position", key: 'position' },
        { title: "Team", dataIndex: "team", key: "key", render: (team: string, rec: LeaderboardPos) => `${rec.emoji} ${team}` },
        { title: "Pts", dataIndex: "points", key: "points" }
    ]

    if (!leaderboard) {
        return null
    }

    return (
        <Table
            dataSource={leaderboard}
            columns={columns}
            rowKey={"position"}
            pagination={false}
        />
    )
}
