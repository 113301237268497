import React, {useEffect, useState} from 'react'
import {Alert, Button, Input, PageHeader} from "antd"
import {Container, EmojiButton, EmojiHint, Middle} from "./common"
import styled from "styled-components"
import {SmileOutlined, PlusOutlined, MinusOutlined} from "@ant-design/icons"
import firebase from 'firebase'
import {GameService} from "../services/game-service"
import Picker, { IEmojiData } from 'emoji-picker-react'
import { useHistory } from 'react-router-dom'
import { Team } from '../models/team'
import { Config } from '../config'
import {BigLoader} from "./loader"

interface TeamsProps {
}

export const CreateTeam: React.FC<TeamsProps> = (props) => {
    const [teamName, setTeamName] = useState<string|null>(null)
    const [showEmojis, setShowEmojis] = useState(false)
    const [emoji, setEmoji] = useState('🤣')
    const [players, setPlayers] = useState<string[]>([''])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string|null>(null)
    const [canCreateTeam, setCanCreateTeam] = useState<boolean|null>(null)
    const history = useHistory()

    useEffect(() => {
        if (teamName !== null) { return }
        const team = GameService.team()
        if (!team) { return }

        setTeamName(team.name)
        setEmoji(team.emoji)
        setPlayers(team.players)
    }, [teamName])

    useEffect(() => {
        if (canCreateTeam != null) { return }

        const verifyGame = async () => {
            const gameId = GameService.gameId()
            const game = await firebase.firestore()
                .collection('games')
                .doc(gameId)
                .get()

            const data = game.data()
            if (!data) {
                return
            }
            setCanCreateTeam((data.state.name === "waiting"))
        }
        verifyGame()
    }, [canCreateTeam])

    const saveTeamTapped = async () => {
        if (!teamName) { return }
        setIsLoading(true)
        const team: Team = {
            name: teamName,
            emoji,
            players: players.filter(name => name.length > 0)
        }

        const gameId = GameService.gameId()

        fetch(`${Config.apiEndpoint}/login?id=${gameId}&teamName=${teamName}`)
            .then(resp => {
                if (!resp.ok) { return resp.json().then((json) => {
                    throw Error(json.error)
                })}
                return resp
            })
            .then(resp => resp.json())
            .then((resp: { token: string }) => {
                return firebase.auth().signInWithCustomToken(resp.token)
            })
            .then(() => {
                return firebase.firestore()
                    .collection('games')
                    .doc(gameId)
                    .collection('teams')
                    .doc(teamName)
                    .set(team)
                    .then(() => {
                        setIsLoading(false)
                        GameService.setTeam(team)
                        history.push('/play')
                    })
                    .catch(console.log)
            })
            .catch((err) => {
                setError(err.toString())
                setIsLoading(false)
            })
    }

    const spectateTapped = () => {
        setIsLoading(true)
        const gameId = GameService.gameId()
        fetch(`${Config.apiEndpoint}/spectate?id=${gameId}`)
            .then(resp => {
                if (!resp.ok) { return resp.json().then((json) => {
                    throw Error(json.error)
                })}
                return resp
            })
            .then(resp => resp.json())
            .then((resp: { token: string }) => {
                return firebase.auth().signInWithCustomToken(resp.token)
            })
            .then(() => {
                setIsLoading(false)
                history.push('/spectate')
            })
    }

    const setPlayerName = (name: string, index: number) => {
        setPlayers(players => {
            let newPlayers = players.slice()
            newPlayers[index] = name
            return newPlayers
        })
    }

    const addPlayer = () => {
        setPlayers(players => {
            let newPlayers = players.slice()
            newPlayers.push('')
            return newPlayers
        })
    }

    const removePlayer = (index: number) => {
        setPlayers(players => {
            let newPlayers = players.slice()
            newPlayers.splice(index, 1)
            return newPlayers
        })
    }

    const emojiChosen = (_: any, emoji: IEmojiData) => {
        setEmoji(emoji.emoji)
        setShowEmojis(false)
    }

    const canSaveTeam = teamName && teamName.length > 0 && players[0].length > 0

    if (canCreateTeam === null) {
        return (
            <Container>
                <Middle>
                    <BigLoader />
                </Middle>
            </Container>
        )
    }

    if (!canCreateTeam) {
        return (
            <Container>
                <PageHeader
                    style={{paddingLeft: 0}}
                    title="Oh no!"
                />

                <span>
                    This game has already started! You can join as a spectator to follow along.
                </span>

                <Button
                    shape="round"
                    size={'large'}
                    style={{ marginTop: 30 }}
                    onClick={spectateTapped}
                    loading={isLoading}
                >
                    Join as Spectator
                </Button>
            </Container>
        )
    }

    return (
        <Container>
            <PageHeader
                style={{paddingLeft: 0}}
                title="Create a Team"
            />

            <Explainer>Only one person from each team can create a team. Make sure your team leader adds everyone as players so you get the credit if you win! Other team members can <strong>Join as Spectator</strong> to enter the game and view the questions as they come up without being able to answer.</Explainer>

            <div>
                <TeamName>
                    <EmojiButton onClick={() => setShowEmojis(showing => !showing)}>
                        {emoji}
                    </EmojiButton>
                    <Input
                        size={"large"}
                        placeholder={"Your team name"}
                        value={teamName ?? ''}
                        onChange={(e) => setTeamName(e.target.value.toLowerCase())}
                        allowClear={true}
                    />
                </TeamName>
                <EmojiHint><span role={"img"} aria-label={"up arrow emoji"}>⬆️</span> Select your team's emoji!</EmojiHint>

                {showEmojis &&
                    <Picker onEmojiClick={emojiChosen}/>}

                <Players>
                    <strong>Players:</strong>
                    {players.map((player, index) => (
                        <AddPlayer key={index}>
                            <Input
                                size={"large"}
                                placeholder={`Player ${index + 1}`}
                                value={player}
                                onChange={(e) => setPlayerName(e.target.value, index)}
                                allowClear={true}
                                style={{marginRight: 8}}
                            />
                            <Button
                                shape="circle"
                                disabled={players.length === 1}
                                icon={<MinusOutlined />}
                                onClick={() => removePlayer(index)}
                            />
                        </AddPlayer>
                    ))}
                    <Button
                        shape="round"
                        icon={<PlusOutlined />}
                        style={{marginTop: 8}}
                        onClick={addPlayer}
                    >
                        Add Player
                    </Button>
                </Players>

                {error &&
                    <Alert message={error} type="error" style={{ marginTop: 15, marginBottom: -20, width: '100%' }}/>}

                <div style={{ display: "flex", justifyContent: "center", marginTop: 40 }}>
                    <Button
                        type="primary"
                        icon={<SmileOutlined/>}
                        shape="round" size={'large'}
                        onClick={saveTeamTapped}
                        disabled={!canSaveTeam}
                        loading={isLoading}
                    >
                        {isLoading ? 'Saving…' : 'Save Team'}
                    </Button>

                    <Button
                        shape="round"
                        size={'large'}
                        style={{ marginLeft: 16 }}
                        onClick={spectateTapped}
                        loading={isLoading}
                    >
                        Join as Spectator
                    </Button>
                </div>
            </div>
        </Container>
    )
}

const Players = styled.div`
    margin-top: 32px;
    width: 100%
`

const AddPlayer = styled.div`
    display: flex;
    flex-direction: row;  
    align-items: center;  
    margin-top: 8px;
`

const TeamName = styled.div`
    display: flex;
    flex-direction: row;  
    align-items: center;  
`

const Explainer = styled.div`
    color: darkgray;
    margin-bottom: 20px;
`
