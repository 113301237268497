import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/app'
import * as serviceWorker from './serviceWorker'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import {NewGame} from "./components/new-game"
import {CreateTeam} from "./components/create-team"
import {PlayGame} from "./components/play-game"
import {AdminLogin} from "./components/admin-login"
import {AdminDashboard} from "./components/admin-dashboard"
import {AdminManageGame} from './components/admin-manage-game'
import {MobileView, BrowserView} from 'react-device-detect'

const MainApp: React.FC = () => {
    return (
        <>
            <MobileView>
                <App>
                    <Router>
                        <Switch>
                            <Route exact path={"/"}>
                                <NewGame/>
                            </Route>
                            <Route exact path={"/teams"}>
                                <CreateTeam/>
                            </Route>
                            <Route exact path={"/play"}>
                                <PlayGame isSpectator={false}/>
                            </Route>
                            <Route exact path={"/spectate"}>
                                <PlayGame isSpectator={true}/>
                            </Route>
                            <Route exact path={"/admin"}>
                                <AdminLogin/>
                            </Route>
                            <Route exact path={"/dashboard"}>
                                <AdminDashboard/>
                            </Route>
                            <Route exact path={"/manage/:gameId"}>
                                <AdminManageGame/>
                            </Route>
                        </Switch>
                    </Router>
                </App>
            </MobileView>
            <BrowserView>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "calc(100vh - 44px)" }}>
                    <div style={{ width: 400, textAlign: "center", lineHeight: 3, fontSize: 16 }}>
                        <strong>Quiz Night</strong> is only playable on mobile devices.<br/>Please <strong><a href={"https://quiznight.fun"}>https://quiznight.fun</a></strong> on your phone.
                    </div>
                </div>
            </BrowserView>
        </>
    )
}

ReactDOM.render(
    <MainApp/>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
// serviceWorker.register()
