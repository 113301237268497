import React, {useEffect, useState} from 'react'
import {Alert, Button, Input, Modal, PageHeader, Table, Switch} from "antd"
import {Link, useHistory} from 'react-router-dom'
import firebase from 'firebase'
import {Game} from '../models/game'
import {Loader} from "./loader"
import {Container, EmojiHint, Middle} from "./common"
import {PlusOutlined, LogoutOutlined} from "@ant-design/icons"
import styled from "styled-components"

interface AdminDashboardProps {
}

export const AdminDashboard: React.FC<AdminDashboardProps> = (props) => {
    const [games, setGames] = useState<Game[]|null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [gameName, setGameName] = useState<string|null>(null)
    const [showNewGameModal, setShowNewGameModal] = useState(false)
    const [error, setError] = useState<string|null>(null)
    const [enableTimer, setEnableTimer] = useState(false)
    const history = useHistory()

    useEffect(() => {
        if (games !== null) { return }
        firebase.auth().onAuthStateChanged( (user) => {
            if (user === null) {
                history.push("/")
                return
            }
            getGames(user.uid)
        })
    }, [games])

    const getGames = async (userId: string|null) => {
        if (!userId) { return }

        try {
            const gamesSnap = await firebase.firestore()
                .collection('games')
                .where('admin', '==', userId)
                .orderBy('startsAt', 'desc')
                .get()

            const allGames = gamesSnap.docs.map(doc => {
                const data = doc.data()
                data.id = doc.id
                return data as Game
            })
            setGames(allGames)
        } catch (e) {
            console.error(e)
        }
    }

    const onLogOutTapped = () => {
        firebase.auth().signOut()
            .then(() => {
                history.push('/')
            })
    }

    const onNewGameTapped = () => {
        setShowNewGameModal(true)
    }

    const createNewGame = async () => {
        if (!gameName) { return }
        setIsLoading(true)
        if (!firebase.auth().currentUser?.uid) { return }
        const gameSnap = await firebase.firestore()
            .collection('games')
            .doc(gameName)
            .get()

        if (gameSnap.exists) {
            setIsLoading(false)
            setError('This Game ID is already taken. Please choose another.')
            return
        }

        const userId = firebase.auth().currentUser!.uid
        firebase.firestore()
            .collection('games').doc(gameName)
            .set({
                admin:  userId,
                startsAt: new Date(),
                state: {
                    name: "waiting",
                },
                teamsCount: 0,
                questions: 0,
                timer: (enableTimer ? 30 : null)
            })
            .then(() => {
                return getGames(userId)
            })
            .then(() => {
                setIsLoading(false)
                setGameName(null)
                setShowNewGameModal(false)
            })
            .catch(e => {
                console.error(e)
                setIsLoading(false)
            })
    }

    const setNewGameName = (name: string) => {
        setError(null)
        setGameName(name.toLowerCase().replace(/\W/g, ''))
    }

    const columns = [
        // @ts-ignore
        {title: 'Games', dataIndex: 'id', key: 'id', render: (_: any, rec: Game) => <Link to={`/manage/${rec.id}`}>{rec.id}</Link>},
        {title: 'Qs', dataIndex: 'questions', key: 'questions'},
        {title: 'Teams', dataIndex: 'teamsCount', key: 'teamsCount'},
        {title: 'State', dataIndex: 'state.name', key: 'state.name', render: (_: any, rec: Game) => rec.state.name}
    ]

    if (!games) {
        return (
            <Container>
                <Middle>
                    <Loader />
                </Middle>
            </Container>
        )
    }

    return (
        <>
            <PageHeader title={"Admin"} />

            <Table
                dataSource={games}
                columns={columns}
                rowKey={"startsAt"}
                pagination={{
                    style: {
                        marginRight: 16
                    }
                }}
            />

            <Container>
                <Button
                    type={"primary"}
                    size={"large"}
                    icon={<PlusOutlined />}
                    onClick={onNewGameTapped}
                    loading={isLoading}
                >
                    New Game
                </Button>

                <LogOutButton
                    type={"default"}
                    size={"large"}
                    icon={<LogoutOutlined />}
                    onClick={onLogOutTapped}
                >
                    Log Out
                </LogOutButton>
            </Container>

            <Modal
                title={"New Game"}
                visible={showNewGameModal}
                onCancel={() => setShowNewGameModal(false)}
                onOk={createNewGame}
                okButtonProps={{ loading: isLoading }}
            >
                <Input
                    size={"large"}
                    placeholder={"Create a Game ID"}
                    value={gameName ?? ''}
                    onChange={(e) => setNewGameName(e.target.value)}
                />
                <EmojiHint>only letters and numbers please!</EmojiHint>

                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 24 }}>
                    <span>Enable 30 second timer</span>
                    <Switch checked={enableTimer} onChange={(isOn) => setEnableTimer(isOn)} />
                </div>

                {error &&
                    <Alert message={error} type="error" style={{ width: '100%' }}/>}
            </Modal>
        </>
    )
}


const LogOutButton = styled(Button)`
    position: fixed;
    bottom: 16px;    
`
