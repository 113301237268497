import styled from "styled-components"

export const Container = styled.div`
    margin: 15px;    
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 375px;
`

export const Middle = styled.div`
    display: flex;    
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
`

export const EmojiButton = styled.div`
    border: 1px solid rgb(217, 217, 217);
    height: 40px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    cursor: pointer;
    font-size: 16pt;
`

export const EmojiHint = styled.span`
    font-size: 10pt;
    color: #b7b7b7;
`
