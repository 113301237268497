import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

interface LoaderProps {
}

export const Loader: React.FC<LoaderProps> = (props) => {
    return (
        <Spin indicator={antIcon} />
    )
}

export const BigLoader: React.FC<LoaderProps> = (props) => {
    return (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 60 }} spin />} />
    )
}

export const SmallLoader: React.FC<LoaderProps> = (props) => {
    return (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 13 }} spin />} />
    )
}
