import React, {useCallback, useEffect, useReducer, useState} from 'react'
import {Button, Input, PageHeader, Progress} from "antd"
import {LikeOutlined} from "@ant-design/icons"
import {Container} from "./common"
import {BigLoader, Loader, SmallLoader} from "./loader"
import styled from "styled-components"
import firebase from 'firebase'
import {GameService} from "../services/game-service"
import {Game, GameState} from "../models/game"
import './animation.css'
import './swipe.css'
import {Question} from "../models/question"
import {Leaderboard} from "./leaderboard"
import {useTimer} from "react-timer-hook"
import {Config} from "../config"

interface PlayGameProps {
    isSpectator: boolean
}

interface PlayGameState {
    pageTitle: string
    round: number
    state: GameState
}

enum PlayGameStateType {
    setCurrentState,
    setIsLoading,
}

interface PlayGameAction {
    type: PlayGameStateType
    payload: any
    round?: number|null
}

const initialState: PlayGameState = {
    pageTitle: 'Loading…',
    round: 0,
    state: GameState.loading,
}

const reducer = (state: PlayGameState, action: PlayGameAction) => {
    switch (action.type) {
        case PlayGameStateType.setIsLoading: {
            return { ...state, isLoading: action.payload as boolean }
        }
        case PlayGameStateType.setCurrentState: {
            switch (action.payload as GameState) {
                case GameState.loading: {
                    return {
                        pageTitle: 'Loading…',
                        round: 0,
                        state: GameState.loading
                    }
                }
                case GameState.waiting: {
                    return {
                        pageTitle: 'Waiting Room',
                        round: 0,
                        state: GameState.waiting
                    }
                }
                case GameState.nextquestion:
                case GameState.getready: {
                    return {
                        pageTitle: '',
                        round: (action.round ?? 0),
                        state: GameState.getready
                    }
                }
                case GameState.question: {
                    return {
                        ...state,
                        pageTitle: `Question ${action.round ?? 0}`,
                        round: (action.round ?? 0),
                        state: GameState.question
                    }
                }
                case GameState.gameover: {
                    return {
                        ...state,
                        pageTitle: 'Game over!',
                        state: GameState.gameover,
                    }
                }
                case GameState.leaderboard: {
                    return {
                        ...state,
                        pageTitle: 'Leaderboard',
                        state: GameState.leaderboard,
                    }
                }
                default:
                    return state
            }
        }
        default:
            return state
    }
}

const useGame = (): Game|null => {
    const [game, setGame] = useState<Game|null>(null)

    useEffect(() => {
        if (game) { return }
        firebase.firestore()
            .collection('games')
            .doc(GameService.gameId())
            .onSnapshot((doc) => {
                const data = doc.data()
                if (!data) {
                    return
                }
                setGame({
                    questions: data.questions,
                    startsAt: data.startsAt.toDate(),
                    teamsCount: data.teamsCount,
                    state: data.state,
                    timer: data.timer
                })
            })
    }, [game])

    return game
}

export const PlayGame: React.FC<PlayGameProps> = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const [question, setQuestion] = useState<Question|null>(null)
    const [hasAlreadyAnswered, setHasAlreadyAnswered] = useState(false)
    const game = useGame()

    useEffect(() => {
        if (!game) { return }
        dispatch({ type: PlayGameStateType.setCurrentState, payload: game.state.name, round: game.state.round })
    }, [game])

    useEffect(() => {
        if (state.round === 0) { return }
        const getRound = async () => {
            const roundSnapshot = firebase.firestore()
                .collection('games').doc(GameService.gameId())
                .collection('rounds').doc(`${state.round}`)

            const results = await Promise.all([
                roundSnapshot.get(),
                Promise.resolve(props.isSpectator)
                    .then(async (isSpectator) => {
                        if (isSpectator) { return true }
                        else {
                            const existsSnap = await roundSnapshot
                                .collection('answers').doc(GameService.team().name)
                                .get()
                            return existsSnap.exists
                        }
                    })
            ])
            const round = results[0]
            const answer = results[1]

            if (answer && !props.isSpectator) {
                setHasAlreadyAnswered(true)
            } else {
                const data = round.data()
                if (data) {
                    setQuestion({text: data.question, emoji: data.emoji})
                    setHasAlreadyAnswered(false)
                }
            }
        }
        getRound()
    }, [state.round])

    let component: React.ReactNode
    switch (state.state) {
        case GameState.loading: {
            component = <LoadingState />
            break
        }
        case GameState.waiting: {
            component = <WaitingRoomState game={game} />
            break
        }
        case GameState.getready:
        case GameState.nextquestion: {
            component = <GetReadyState />
            break
        }
        case GameState.question: {
            console.log(`Has answered round ${state.round}`, hasAlreadyAnswered)
            component = <QuestionState
                round={state.round}
                question={question}
                hasSubmitted={hasAlreadyAnswered}
                setHasSubmitted={setHasAlreadyAnswered}
                isSpectator={props.isSpectator}
                timer={game?.timer ? (game.timer + 1) : null}
            />
            break
        }
        case GameState.gameover: {
            component = <GameOverState />
            break
        }
        case GameState.leaderboard: {
            component = <Leaderboard gameId={GameService.gameId()} />
            break
        }
        default:
            component = <span>beep boop <span role={"img"} aria-label={"robot emoji"}>🤖</span> you should not see this</span>
    }

    return (
        <Container>
            <PageHeader
                style={{paddingLeft: 0}}
                title={state.pageTitle}
            />
            {component}
        </Container>
    )
}

interface WaitingRoomProps {
    game: Game|null
}

const WaitingRoomState: React.FC<WaitingRoomProps> = (props) => {
    return (
        <WaitingRoomContainer>
            <BigLoader />
            <WaitingArea>Waiting for the game to start…</WaitingArea>
            {props.game &&
                <Extra>
                    This game has <strong>{props.game.questions}</strong> question{props.game.questions === 1 ? '' : 's'}.<br />
                    Currently there {props.game.teamsCount === 1 ? 'is' : 'are'}&nbsp;
                    <strong>{props.game.teamsCount === 0 ? 'no' : props.game.teamsCount}</strong> team{props.game.teamsCount === 1 ? '' : 's'} playing.
                </Extra>}
        </WaitingRoomContainer>
    )
}

const LoadingState: React.FC = () => {
    return (
        <WaitingRoomContainer>
            <Loader />
        </WaitingRoomContainer>
    )
}

const GetReadyState: React.FC = () => {
    const { seconds } = useTimer({ expiryTimestamp: Date.now() + Config.getReadyTimerLength })

    return (
        <GetReadyContainer>
            {seconds > 0 &&
                <>
                    <span>Get Ready!</span>
                    <CountdownTimer>
                        {seconds}
                    </CountdownTimer>
                </>}
            {seconds === 0 &&
                <BigLoader/>}
        </GetReadyContainer>
    )
}

interface QuestionStateProps {
    round: number
    question: Question|null
    hasSubmitted: boolean
    setHasSubmitted: (hasSubmitted: boolean) => void
    isSpectator: boolean
    timer: number|null
}

const QuestionState: React.FC<QuestionStateProps> = (props) => {
    const [isDisabled, setIsDisabled] = useState(false)
    const [answer, setAnswer] = useState('')
    const { seconds } = useTimer({ expiryTimestamp: Date.now() + ((props.timer ?? 0) * 1000) })
    let timeLeft = 0
    if (props.timer) {
        timeLeft = (100 / props.timer) * (props.timer - seconds)
    }

    const onSubmitEnd = () => {
        setIsDisabled(true)

        firebase.firestore()
            .collection('games').doc(GameService.gameId())
            .collection('rounds').doc(`${props.round}`)
            .collection('answers').doc(GameService.team().name)
            .set({
                answer,
                answeredAt: new Date(),
                emoji: GameService.team().emoji,
                isCorrect: false,
            })
            .then(() => {
                props.setHasSubmitted(true)
            })
    }

    if (props.hasSubmitted) {
        return (
            <QuestionContainer>
                <LikeOutlined style={{ fontSize: 70 }} />
                <div style={{ marginTop: 32, fontSize: 16 }}>Answer submitted!</div>
            </QuestionContainer>
        )
    }

    if (!props.question) {
        return null
    }

    const timesUp: boolean = (props.timer !== null && timeLeft === 100)

    return (
        <QuestionContainer>
            <span style={{ fontSize: 60 }} role={"img"} aria-label={"emoji describing question"}>{props.question.emoji}</span>
            <QuestionText>{props.question.text}</QuestionText>

            {props.timer &&
                <Progress
                    percent={timeLeft}
                    format={(percent, success) => `${Math.floor(props.timer! - ((props.timer! / 100) * (percent ?? 0)))}s`}
                />}

            {!props.isSpectator &&
                <>
                    <Input
                        size={"large"}
                        placeholder={"Your answer"}
                        value={answer}
                        onChange={(e) => setAnswer(e.target.value)}
                        allowClear={true}
                        autoFocus={true}
                        disabled={isDisabled}
                    />

                    {!props.timer && answer.length > 0 &&
                        <div style={{display: "flex", justifyContent: "center", flexDirection: "column", marginTop: 50, width: "60%"}}>
                        {!isDisabled &&
                            <Button
                                size={"large"}
                                onClick={onSubmitEnd}
                                type={"primary"}
                                shape={"round"}
                            >
                                Tap to submit
                            </Button>}
                        {isDisabled &&
                            <Loader/>}
                    </div>}

                    {props.timer &&
                        <div style={{display: "flex", justifyContent: "center", flexDirection: "column", marginTop: 50, width: "60%"}}>
                            {timesUp &&
                                <Button
                                    size={"large"}
                                    onClick={onSubmitEnd}
                                    type={"primary"}
                                    shape={"round"}
                                    disabled={true}
                                >
                                    Times up!
                                </Button>}
                            {!timesUp && !isDisabled &&
                                <Button
                                    size={"large"}
                                    onClick={onSubmitEnd}
                                    type={"primary"}
                                    shape={"round"}
                                    >
                                        Tap to submit
                                </Button>}
                            {!timesUp && isDisabled &&
                                <Loader/>}
                        </div>
                    }
                </>
            }
        </QuestionContainer>
    )
}

const GameOverState: React.FC = () => {
    return (
        <GameOverContainer>
            <GameOverEmoji role={"img"} aria-label={"celebration emoji"}>🎉</GameOverEmoji>
            <GameOverText><strong>Thanks for playing!</strong><br /><SmallLoader />&nbsp;&nbsp;Calculating leaderboard…</GameOverText>
        </GameOverContainer>
    )
}


const WaitingRoomContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 47px);
`

const Extra = styled.div`
    margin-top: 30px;
    text-align: center;
`

const WaitingArea = styled.div`
    margin: 30px 0;
`

const GetReadyContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 24px;
    font-weight: 600;
    height: calc(100vh - 47px);
`

const QuestionContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const QuestionText = styled.blockquote`
    text-align: center;
    font-family: 'Josefin Slab', serif;
    font-weight: 600;
    font-size: 20pt;
`

const GameOverContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const GameOverEmoji = styled.span`
    display: block;
    font-size: 99pt;
`

const GameOverText = styled.div`
    text-align: center;
    font-size: 13pt;
    margin-top: 40px;
    line-height: 40px;
`

const CountdownTimer = styled.div`
    font-size: 45pt;
`


