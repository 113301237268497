import React, {useState} from 'react'
import styled from "styled-components"
import {Alert, Button, Input, PageHeader} from "antd"
import firebase from 'firebase'
import {useHistory} from "react-router-dom"

interface AdminProps {
}

export const AdminLogin: React.FC<AdminProps> = (props) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState<string|null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory()

    const onLogInClicked = () => {
        setIsLoading(true)

        firebase.auth()
            .signInWithEmailAndPassword(email, password)
            .then(() => {
                setError(null)
                setIsLoading(false)
                history.push("/dashboard")
            })
            .catch(() => {
                setError('Invalid username or password.')
                setIsLoading(false)
            })
    }


    return (
        <>
            <PageHeader title={"Quiz Night Admin"} />
            <AdminContainer>
                <form>
                    <Input size={"large"} placeholder={"Email"} autoFocus={true} onChange={(e) => setEmail(e.target.value)} />
                    <Input.Password size={"large"} placeholder={"Password"} style={{ marginTop: 25 }} onChange={(e) => setPassword(e.target.value)} />

                    {error &&
                     <Alert message={error} type="error" style={{ marginTop: 25, marginBottom: -10, width: '100%' }}/>}

                    <Button size={"large"} type={"primary"} style={{ marginTop: 25, width: '100%' }} loading={isLoading} onClick={onLogInClicked}>
                        Log in
                    </Button>
                </form>
            </AdminContainer>
        </>
    )
}

const AdminContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
    margin: 0 20px;
`
